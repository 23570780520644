import React from 'react'
import Layout from '../../components/layout'
import {StaticImage} from 'gatsby-plugin-image'
import InfoContactUsForm from '../../components/info-contact-us-form'
import CTA from '../../components/cta'
import {navigate} from "gatsby";

const subscriberLpZendeskCxTrends2021Report = process.env.GATSBY_SUBSCRIBER_LP_ZENDESK_CX_TRENDS_2021_REPORT_FORM_TAG;


const ZendeskCXTrends = ({location}) => (
  <Layout>
    <section>
      <div className="container">
        <div className="row">
          <div className="col">
            <StaticImage
              src="../../images/lp/729_LP_CX-Trends-Report_May_2021_A.png"
              alt="Zendesk CX Trends 2021"
            />
          </div>
        </div>
      </div>
    </section>

    <section className="pt-6">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <h1>New CX for a new world</h1>
            <p>
              Last year, we learned that customer needs can change in a blink, and your support team needs to change
              almost as fast. The 2021 Zendesk CX Trends Report will help you learn how to keep up.
            </p>
            <h2>Looking to 2021 with 2020/vision</h2>
            <p>
              A team of experts reviewed data from 90,000 businesses using Zendesk across 175 countries. They compared
              how businesses use Zendesk with opinions from customers, agents, customer experience leaders, and
              technology buyers. Using these insights, they identified this year’s top trends in customer experience
              and tailored best practices to your team.
            </p>
            <h2>Let's get digital</h2>
            <p>
              COVID-19 moved many customers online—leaving businesses scrambling to spruce up their digital
              storefronts. Adding digital tools is essential to scaling customer interactions. Leverage the 2021
              Zendesk CX Trends Report now to show you the way.
            </p>
          </div>

          <div className="col-lg-4">
            <div className="container bg-light py-3">
              <h2>Immerse yourself in the interactive report</h2>
              <p>Complete and submit this form:</p>
              <InfoContactUsForm
                location={location}
                tags={[subscriberLpZendeskCxTrends2021Report]}
                onSubmitSuccess={() => navigate('/lp/zendesk-cx-trends-2021-report-ty')}/>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="pt-6">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2>Stay on Trend</h2>
            <p>
              Despite the massive shakeup in customer and employee experiences, some things remain the same: building
              trust and connection is crucial to keeping everyone happy.
            </p>
          </div>
        </div>

        <div className="row pt-3">
          <div className="col-lg-6 text-center">
            <StaticImage
              src="../../images/lp/729-Icons_Thumbs-Up-multi-1.png"
              alt="Spotlight on CX"
              className="mb-4"
            />
            <h6>Trend 1</h6>
            <h4>Spotlight on CX</h4>
            <p>
              The rapid move toward online engagement has reshaped what customers expect from service interactions,
              and 75% of customers will spend more to buy from a company that offers good CX.
            </p>
          </div>

          <div className="col-lg-6 text-center">
            <StaticImage
              src="../../images/lp/729-Icons_Customer-Service-Help-multi.png"
              alt="A More Conversational World"
              className="mb-4"
            />
            <h6>Trend 2</h6>
            <h4>A More Conversational World</h4>
            <p>
              Customers want it all. They’re flocking to social messaging and exploring new buying habits—changing
              their behavior for good. 64% of customers started using a new customer service channel in 2020.
            </p>
          </div>
        </div>

        <div className="row pt-3">
          <div className="col-lg-6 text-center">
            <StaticImage
              src="../../images/lp/729-Icons_Puzzle2-minotaur.png"
              alt="Emphasis on Agility"
              className="mb-4"
            />
            <h6>Trend 3</h6>
            <h4>Emphasis on Agility</h4>
            <p>
              Facing continued volatility, teams must find ways to adapt to the new needs of their customers and
              employees. 85% of teams reported having to make changes to their support in 2020.
            </p>
          </div>

          <div className="col-lg-6 text-center">
            <StaticImage
              src="../../images/lp/729-Icons_Training-multi.png"
              alt="The Future of Work is Now"
              className="mb-4"
            />
            <h6>Trend 4</h6>
            <h4>The Future of Work is Now</h4>
            <p>
              Support teams have seen a lot of change, and new pressures are forcing companies to rethink how
              employees work. 50% of teams reported going fully remote in 2020.
            </p>
          </div>
        </div>

        <div className="row pt-3">
          <div className="col text-center">
            <StaticImage
              src="../../images/lp/729-Icons_Pinpoint-multi.png"
              alt="The Digital Tipping Point"
              className="mb-4"
            />
            <h6>Trend 5</h6>
            <h4>The Digital Tipping Point</h4>
            <p>
              Companies are rapidly adding tools to help them scale their operations, personalize conversations,
              prioritize employees, and meet customers where they are. 75% of decision makers say COVID has
              accelerated adoption of digital tech.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section className="mt-6 py-6 bg-light">
      <div className="container">
        <div className="row">
          <div className="col d-flex align-items-center justify-content-center">
            <CTA href="/contact-us" text="GET HELP WITH A MIGRATION NOW" className="btn btn-primary-light"/>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default ZendeskCXTrends
